import { useLayoutEffect, useMemo } from 'react';

import { ThemeProvider } from 'next-themes';
import { useTranslation } from 'react-i18next';

import { createTheme, Typography } from '@maistodos/design-system-web';

import { Footer, FooterContent } from 'components/Footer';
import { Header, HeaderLogo } from 'components/Header';
import { Main } from 'components/Main';

import { useLinkId } from 'hooks/useLinkId';

import { useLink } from 'services/useLink';

import { isLanguageSupported } from 'utils/formatLocale';

import { DefaultProps } from './types';

import './font-faces.css';

export const Default = ({ children }: DefaultProps) => {
  const { t, i18n } = useTranslation();
  const id = useLinkId();
  const { data } = useLink(id);

  useLayoutEffect(() => {
    i18n.changeLanguage(isLanguageSupported(data?.locale_code) ?? 'pt_BR');
  }, [data, i18n]);

  const alt = `Logo ${data?.company.name}`;

  const { className: forcedTheme } = useMemo(() => {
    if (!data) {
      return createTheme({
        colors: {
          brand500: '$neutral300',
          header: '$neutral300',
        },
      });
    }

    return createTheme({
      colors: {
        brand500: data.theme.primary_color,
        brand600: data.theme.hover_primary_color,
        brand700: data.theme.hover_primary_color,
        header: data.theme.secondary_color,
      },
    });
  }, [data]);

  return (
    <ThemeProvider
      attribute="class"
      defaultTheme=""
      themes={[]}
      forcedTheme={forcedTheme}
      enableSystem={false}
    >
      <Header>
        {data && <HeaderLogo src={data.theme.file_logo} alt={alt} />}
      </Header>

      <Main>{children}</Main>

      {data && (
        <Footer>
          <FooterContent>
            <img src={data.theme.file_logo} alt={alt} height="100%" />
          </FooterContent>

          <Typography variant="small">
            {t('Todos os direitos reservados')}
          </Typography>
          <Typography variant="small">
            {data.company.name} - CNPJ {data.company.document}
            <br />
            {data.company.address}
          </Typography>
        </Footer>
      )}
    </ThemeProvider>
  );
};
