import { useEffect, useMemo } from 'react';

import { useTranslation } from 'react-i18next';

import { useLinkId } from 'hooks/useLinkId';

import { Receipt } from 'modules/checkout/screens/Receipt';

const Page = () => {
  const { t } = useTranslation();
  const title = useMemo<string>(() => t('Comprovante de pagamento'), [t]);
  const id = useLinkId();

  useEffect(() => {
    document.title = title;
  }, [title]);

  return <Receipt id={id} title={title} />;
};

export default Page;
