import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { baseApi } from 'libs/axios';

import { parseData } from 'utils/parseData';

import { PaymentParams, PaymentResponse } from './types';

export const usePay = (id: string) => {
  return useMutation<PaymentResponse, AxiosError, PaymentParams>((data) => {
    return baseApi
      .post<PaymentResponse>(`/links/${id}/pay/`, data)
      .then(parseData);
  });
};
