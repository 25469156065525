import { useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import { Separator, Typography, Flex } from '@maistodos/design-system-web';

import { Content } from 'components/Content';

import { LinkStatus } from 'entities/LinkStatus';

import { useLink } from 'services/useLink';

import {
  formatLocaleCustomerDocument,
  formatLocaleCurrency,
  isLanguageSupported,
} from 'utils/formatLocale';

import { PaymentLoading } from 'modules/checkout/components/Payment/PaymentLoading';
import { PaymentNotFound } from 'modules/checkout/components/Payment/PaymentNotFound';
import { SectionGroup } from 'modules/checkout/components/Section/SectionGroup';
import { SectionText } from 'modules/checkout/components/Section/SectionText';
import { SectionTitle } from 'modules/checkout/components/Section/SectionTitle';
import { usePaymentCheck } from 'modules/checkout/hooks/usePaymentCheck';

import { RefundedProps } from './types';

export const Refunded = ({ id, title }: RefundedProps) => {
  const { t, i18n } = useTranslation();
  const { data, isLoading, error } = useLink(id);
  const { isValid } = usePaymentCheck(id, LinkStatus.Refunded);

  useEffect(() => {
    i18n.changeLanguage(isLanguageSupported(data?.locale_code) ?? 'pt_BR');
  }, [data, i18n]);

  if (error) {
    return <PaymentNotFound locale_code={error.response?.data?.locale_code} />;
  }

  if (isLoading || !isValid) {
    return (
      <Content title={t(title)}>
        <PaymentLoading />
      </Content>
    );
  }

  return (
    <Content title={t(title)}>
      <Flex direction="column" css={{ gridRowGap: '$spacing24' }}>
        {/* Customer details */}
        <SectionGroup direction="column">
          <SectionTitle>{t('Dados pessoais')}</SectionTitle>
          <SectionText>{data.customer.name}</SectionText>
          {data.customer.email && (
            <SectionText>E-mail: {data.customer.email}</SectionText>
          )}
          {!!data?.customer?.document && (
            <SectionText>
              {`${t('Documento')}: ${formatLocaleCustomerDocument(
                data.customer.document,
                i18n.language
              )}`}
            </SectionText>
          )}
        </SectionGroup>

        {/* Issuer details */}
        <SectionGroup direction="column">
          <SectionTitle>{t('Nome do emissor')}</SectionTitle>
          <SectionText>{data.company.name}</SectionText>
        </SectionGroup>

        <Separator />

        <SectionGroup direction="column">
          <SectionTitle>{t('Resumo do pagamento')}</SectionTitle>
          <SectionText>{data.messages.main}</SectionText>
        </SectionGroup>

        <SectionGroup direction="column">
          <Typography variant="medium" css={{ color: '$neutral400' }}>
            {t('Valor estornado:')}
          </Typography>
          <Typography variant="medium" css={{ color: '$neutral600' }}>
            {formatLocaleCurrency(data.amount, i18n.language)}
          </Typography>
        </SectionGroup>
      </Flex>
    </Content>
  );
};
