import { useNavigate } from 'react-router-dom';

import { LinkStatus } from 'entities/LinkStatus';

import { useLink } from 'services/useLink';

import { LinkCheck } from './types';

export const usePaymentCheck = (id: string, status: LinkStatus) => {
  const navigate = useNavigate();

  const replace = (url: string) => {
    window.scrollTo(0, 0);
    navigate(url, { replace: true });
  };

  const { data } = useLink<LinkCheck>(id, {
    notifyOnChangeProps: ['data'],
    onSuccess: (response) => {
      if (!response.isValid) {
        const destiny: Record<LinkStatus, string> = {
          [LinkStatus.Created]: '',
          [LinkStatus.Paid]: '/receipt',
          [LinkStatus.Refunded]: '/refunded',
        };

        replace(`/${id}${destiny[response.status]}`);
      }
    },
    select: (response) => {
      return {
        isValid: Boolean(status === response.status),
        status: response.status,
      };
    },
  });

  return {
    isValid: Boolean(data?.isValid),
  };
};
