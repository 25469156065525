import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { format, parse } from 'date-fns';
import { useTranslation } from 'react-i18next';

import { Info } from '@maistodos/core-icons';
import {
  Button,
  Typography,
  Toast,
  Box,
  Flex,
  Loading,
} from '@maistodos/design-system-web';
import { ToastRef } from '@maistodos/design-system-web/types/components/Feedback/Toast/types';

import { PaymentFlowAnalytics } from 'analytics/payment-flow-analytics';
import Boleto from 'boleto.js';

import { usePay } from 'services/usePay';

import { copyToClipboard } from 'utils/copyToClipboard';
import { formatLocaleCurrency } from 'utils/formatLocale';

import { SectionGroup } from 'modules/checkout/components/Section/SectionGroup';
import { SectionText } from 'modules/checkout/components/Section/SectionText';
import { SectionTitle } from 'modules/checkout/components/Section/SectionTitle';

import { FormBankSlipProps } from './types';

export const FormBankSlip = ({
  id,
  amount,
  paymentType,
}: FormBankSlipProps) => {
  const { t, i18n } = useTranslation();
  const toast = useRef<ToastRef>(null);
  const bankslip = useRef<Boleto | undefined>(undefined);

  const [barcodePretty, setBarCodePretty] = useState<string>('');

  const { mutate, data } = usePay(id);
  const code = data?.bankslip?.barcode;

  const formatted = useMemo<string>(
    () => formatLocaleCurrency(amount, i18n.language),
    [amount, i18n.language]
  );

  const expDate = useMemo<string>(() => {
    if (!data?.bankslip?.exp_date) return '00/00/0000';

    const date = parse(data.bankslip.exp_date, 'yyyy-MM-dd', new Date());
    return format(date, 'dd/MM/yyyy');
  }, [data]);

  const onCopy = useCallback(() => {
    if (!data?.bankslip) return;

    PaymentFlowAnalytics.onClickCopyCode();
    toast.current?.publish();
    copyToClipboard(data.bankslip.barcode);
  }, [data]);

  useEffect(() => {
    mutate({
      payment_type: paymentType.id,
    });
  }, [mutate, paymentType]);

  useEffect(() => {
    if (!code) return;
    bankslip.current = new Boleto(code);
    bankslip.current.toSVG('#barcode');
    setBarCodePretty(bankslip.current.prettyNumber());
  }, [code]);

  if (!data) {
    return <Loading color="$brand500" fontSize="$h1" />;
  }

  return (
    <div>
      <Flex
        direction="column"
        css={{
          gridRowGap: '$spacing24',
          width: '100%',
        }}
      >
        <SectionGroup direction="column">
          <SectionTitle>{t('Data de vencimento')}</SectionTitle>
          <SectionText>{expDate}</SectionText>
        </SectionGroup>

        <SectionGroup direction="column">
          <SectionTitle>{t('Valor do boleto')}</SectionTitle>
          <SectionText>{formatted}</SectionText>
        </SectionGroup>

        <Box css={{ width: '100%' }} id="barcode" />

        <Typography align="center" variant="small" weight="bold">
          {barcodePretty || '?'}
        </Typography>

        <Box
          css={{
            maxWidth: 450,
            marginLeft: '$auto',
            marginRight: '$auto',
          }}
        >
          <Button testId="copy" variant="primary" onClick={onCopy} fluid>
            {t('Copiar código de barras')}
          </Button>
        </Box>
      </Flex>

      <Toast
        ref={toast}
        icon={<Info color="currentColor" fontSize="1em" />}
        title={t('Código copiado com sucesso.')}
      />
    </div>
  );
};
