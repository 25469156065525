import { useEffect, useMemo } from 'react';

import { useTranslation } from 'react-i18next';

import { Error404 } from 'modules/checkout/screens/Error404';

const Page = () => {
  const { t } = useTranslation();
  const title = useMemo<string>(() => t('Pagamento não encontrado'), [t]);

  useEffect(() => {
    document.title = title;
  }, [title]);

  return <Error404 />;
};

export default Page;
