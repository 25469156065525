import { useEffect } from 'react';

import { BlobProvider } from '@react-pdf/renderer';
import { useTranslation } from 'react-i18next';

import { Print } from '@maistodos/core-icons';
import {
  Button,
  Separator,
  Typography,
  Flex,
} from '@maistodos/design-system-web';

import { PaymentFlowAnalytics } from 'analytics/payment-flow-analytics';

import { Content } from 'components/Content';

import { LinkStatus } from 'entities/LinkStatus';

import { useLink } from 'services/useLink';

import { downloadBlob } from 'utils/downloadBlob';
import {
  formatLocaleCustomerDocument,
  formatLocaleCurrency,
  isLanguageSupported,
} from 'utils/formatLocale';

import { PaymentLoading } from 'modules/checkout/components/Payment/PaymentLoading';
import { PaymentNotFound } from 'modules/checkout/components/Payment/PaymentNotFound';
import { ReceiptPdf } from 'modules/checkout/components/ReceiptPdf';
import { SectionGroup } from 'modules/checkout/components/Section/SectionGroup';
import { SectionText } from 'modules/checkout/components/Section/SectionText';
import { SectionTitle } from 'modules/checkout/components/Section/SectionTitle';
import { usePaymentCheck } from 'modules/checkout/hooks/usePaymentCheck';

import { ReceiptProps } from './types';

export const Receipt = ({ id, title }: ReceiptProps) => {
  const { t, i18n } = useTranslation();
  const { data, isLoading, error } = useLink(id);
  const { isValid } = usePaymentCheck(id, LinkStatus.Paid);

  useEffect(() => {
    i18n.changeLanguage(isLanguageSupported(data?.locale_code) ?? 'pt_BR');
  }, [data, i18n]);

  if (error) {
    return <PaymentNotFound locale_code={error.response?.data?.locale_code} />;
  }

  if (isLoading || !isValid) {
    return (
      <Content title={t(title)}>
        <PaymentLoading />
      </Content>
    );
  }

  return (
    <Content title={t(title)}>
      <Flex direction="column" css={{ gridRowGap: '$spacing24' }}>
        {/* Customer details */}
        <SectionGroup direction="column">
          <SectionTitle>{t('Dados pessoais')}</SectionTitle>
          <SectionText>{data.customer.name}</SectionText>
          {!!data.customer.email && (
            <SectionText>E-mail: {data.customer.email}</SectionText>
          )}
          {!!data.customer.document && (
            <SectionText>
              {`${t('Documento')}: ${formatLocaleCustomerDocument(
                data.customer.document,
                i18n.language
              )}`}
            </SectionText>
          )}
        </SectionGroup>

        {/* Issuer details */}
        <SectionGroup direction="column">
          <SectionTitle>{t('Nome do emissor')}</SectionTitle>
          <SectionText>{data.company.name}</SectionText>
        </SectionGroup>

        <Separator />

        <SectionGroup direction="column">
          <SectionTitle>{t('Resumo do pagamento')}</SectionTitle>
          <SectionText>{data.messages.main}</SectionText>
        </SectionGroup>

        <SectionGroup direction="column">
          <Typography variant="medium" css={{ color: '$neutral400' }}>
            {t('Valor total:')}
          </Typography>
          <Typography variant="medium" css={{ color: '$neutral600' }}>
            {formatLocaleCurrency(data.amount_paid, i18n.language)}
          </Typography>
        </SectionGroup>
      </Flex>

      <Flex
        direction="row"
        justifyContent="spaceBetween"
        css={{ marginTop: '$spacing32', marginBottom: '$spacing8' }}
      >
        <Typography variant="h6" css={{ color: '$neutral600' }}>
          {t('Valor pago:')}
        </Typography>
        <Typography variant="h6" css={{ color: '$neutral600' }}>
          {formatLocaleCurrency(data.amount_paid, i18n.language)}
        </Typography>
      </Flex>

      <Separator css={{ marginBottom: '$spacing32' }} />

      <Flex
        direction="column"
        css={{
          gridRowGap: '$spacing16',
          maxWidth: 256,
          marginLeft: '$auto',
          marginRight: '$auto',
        }}
      >
        <BlobProvider document={<ReceiptPdf data={data} />}>
          {({ blob, loading }) => (
            <Button
              testId="print"
              variant="primary"
              onClick={() => {
                if (!blob) return;
                downloadBlob('recibo.pdf', blob);
                PaymentFlowAnalytics.onClickPrint();
              }}
              endAdornment={
                <Print color="currentColor" fontSize="calc(1em + 30%)" />
              }
              loading={loading}
              fluid
            >
              {t('Imprimir')}
            </Button>
          )}
        </BlobProvider>
      </Flex>
    </Content>
  );
};
