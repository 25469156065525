import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { LinkAPIError } from 'entities/Response';

import { baseApi } from 'libs/axios';

import { createUseLinkKey } from 'services/useLink/key';

import { parseData } from 'utils/parseData';

import { Link } from './types';

export const useLink = <T = Link>(
  id: string,
  options?: UseQueryOptions<Link, AxiosError<LinkAPIError>, T>
) => {
  return useQuery<Link, AxiosError<LinkAPIError>, T>(
    createUseLinkKey(id),
    () => baseApi.get<Link>(`/links/${id}`).then(parseData),
    {
      enabled: Boolean(id),
      ...options,
    }
  );
};
