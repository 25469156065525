import { useEffect, useMemo } from 'react';

import { useTranslation } from 'react-i18next';

import { PaymentFlowAnalytics } from 'analytics/payment-flow-analytics';

import { useLinkId } from 'hooks/useLinkId';

import { Payment } from 'modules/checkout/screens/Payment';

const Page = () => {
  const { t } = useTranslation();
  const title = useMemo<string>(() => t('Pagamento'), [t]);
  const id = useLinkId();

  useEffect(() => {
    document.title = title;
  }, [title]);

  useEffect(() => {
    PaymentFlowAnalytics.registerPaymentProperties(id);
  }, [id]);

  return <Payment id={id} title={title} />;
};

export default Page;
