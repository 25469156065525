import { Outlet } from 'react-router-dom';

import { globalCss, globalStyles } from '@maistodos/design-system-web';

import { Default } from 'layouts/Default';

import { ReactQuery } from 'libs/react-query';

const customGlobalStyles = globalCss({
  body: {
    backgroundColor: '$brand500',

    '@bp1': {
      backgroundColor: '$neutral100',
    },
  },
});

globalStyles();
customGlobalStyles();

const MyApp = () => {
  return (
    <ReactQuery>
      <Default>
        <Outlet />
      </Default>
    </ReactQuery>
  );
};

export default MyApp;
